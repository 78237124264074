import { useQuery } from "react-query";
import PHONE from "../../assets/image/Icon/phone.png";
import { homeServices } from "../../services/apiService/homeService";

export default function ContactPhoneZalo() {
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.campaignDetailsApi("1"),
  });
  return (
    <div className="fixed bottom-32 z-50 right-2 flex flex-col justify-center items-center">
      <a href={`tel:${data?.hotline}`}>
        <img src={PHONE} className="w-14" />
      </a>
    </div>
  );
}
