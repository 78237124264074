import { useEffect, useState } from "react";
import VIDEO from "../../assets/video/video.mp4";
import Success from "../SuccessPrize/SuccessPrize";

export default function PlayGame() {
  const lst_gift = JSON.parse(localStorage.getItem("LIST_GIFT") || "{}");

  return (
    <div>
      <Success prize={lst_gift} />
    </div>
  );
}
