import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import "../../assets/css/ListGift.css";
import { useEffect, useState } from "react";
import GiftItem from "./GiftItem";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";
import { PageGift } from "../../models/GiftModel";
import MainNavBar from "../../components/NavBar/MainNavBar";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import PopupPrize from "../../components/Popup/PopupPrize";

export default function ListGift() {
  let appCode = window.location.pathname.split("/")[1];
  const [openPopup, setOpenPopup] = useState(false);
  const [statusGifts, setStatusGifts] = useState("done");
  const [listPage, setListPage] = useState<PageGift>();
  const [reward_type, setRewardType] = useState<string>("all");
  const [amountGift, setAmountGift] = useState<number>(0);

  const getData = (page: number, reward_type: string) => {
    const data = {
      page: page,
      limit: 100,
      reward_type: reward_type,
    };
    ListReceiptServices.GetListMyGift(data)
      .then((res: any) => {
        console.log(res);
        const amountStatus = res?.list_prizes;
        console.log(amountStatus);
        console.log(amountStatus?.length);
        setAmountGift(amountStatus?.length);
        setListPage(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const getMyListGift = (val: string, appCode: string) => {
    console.log(appCode);
  };
  useEffect(() => {
    getData(1, reward_type);
    getMyListGift(statusGifts, appCode);
    disableButtonBack();
    setRewardType("s");
  }, []);
  const disableButtonBack = () => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  };
  return (
    <div>
      <HeaderBackground title="Quà của tôi" buttonBack="/" />
      <div className="containerNotify__background absolute bottom-0 h-[87%] w-full z-10">
        <ul className="containerNotify__background-listnoti relative z-0 pt-2 box-border ">
          {listPage?.list_prizes?.map((infor: any, index: number) => {
            return (
              <li
                className="mx-[25px] bg-[#f0f0f0] rounded-xl mb-4 overflow-hidden"
                key={index}
              >
                <GiftItem infor={infor} setOpenPopup={setOpenPopup} />
              </li>
            );
          })}
          <div className="h-20"></div>
        </ul>
        <MainNavBar />
      </div>
      <PopupPrize
        isShow={openPopup}
        notify={"Hướng dẫn sử dụng"}
        content={localStorage.getItem("prize")}
        handleOk={setOpenPopup}
      />
      <ContactPhoneZalo />
    </div>
  );
}
