import { useNavigate } from "react-router-dom";

export default function HeaderBackground({
  title,
  buttonBack,
}: {
  title: string;
  buttonBack: string;
}) {
  const navigation = useNavigate();

  const handleBack = () => {
    console.log(buttonBack);
    navigation(buttonBack);
  };
  return (
    <div className="contain">
      <div className="containerNotify w-full min-h-full h-[291px] bg-no-repeat absolute left-0 top-0">
        <div className="containerNotify__header h-[14%] flex items-center p-[0_20px_0_20px]">
          <div
            className="containerNotify__header-btnBack h-[57px] w-[57px] opacity-80 cursor-pointer"
            onClick={handleBack}
          ></div>
          <div className="containerNotify__header-content uppercase font-bold-go flex text-center justify-center text-white text-[20px] ">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}
