import ICON_HISTORY_PARTICI from "../../assets/image/Icon/icon-history-participate.svg";
import ICON_MYGIFT from "../../assets/image/Icon/icon-mygift.svg";
import ICON_RIGHT from "../../assets/image/Icon/icon-right.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MY_GAME from "../../assets/image/Icon/mygame.svg";
import LOGOUT from "../../assets/image/Icon/Logout.png";
import HeaderBackground from "../../components/HeaderBackground/HeaderBackground";
import Logo from "../../components/NavBar/Logo";
import ContactPhoneZalo from "../../components/ContactPhoneZalo/ContactPhoneZalo";
import PopupNotify from "../../components/Popup/PopupError";
import { ListReceiptServices } from "../../services/apiService/ListReceiveService";

const TITLE = "Tài khoản";

export default function InforCustomer() {
  localStorage.setItem("linkRedirect", `/infor-customer`);
  const navigation = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const handleMyGift = () => {
    navigation(`/gifts`);
  };
  const handleHistory = () => {
    navigation(`/list-history`);
  };
  const handlePrize = () => {
    navigation("/prize");
  };
  const handleLogout = () => {
    setIsLogin(true);
  };
  const handleOk = () => {
    localStorage.clear();
    navigation("/login");
  };
  const handleCancel = () => {
    setIsLogin(false);
  };
  useEffect(() => {
    getData(1, 10, "", "");
    window.scrollTo(0, 0);
  }, []);
  const getData = (
    page: number,
    limit: number,
    start_date: string,
    end_date: string
  ) => {
    const data = {
      page: page,
      limit: 10,
      start_date: start_date,
      end_date: end_date,
    };
    ListReceiptServices.GetListHistory(data)
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <HeaderBackground title={TITLE} buttonBack={`/`} />
      <div className="containerNotify__background bg-[#fff] absolute rounded-[30px_30px_0_0] bottom-0 h-[87%] w-full z-10">
        <ul className="containerNotify__background-list mt-8 mx-6 box-border z-20 grid-rows-2 border-2 rounded-lg">
          <li className="content-info-li border-b-2" onClick={handleMyGift}>
            <div className="icon-infor-li -ml-1">
              <img src={ICON_MYGIFT} />
            </div>
            <div className=" -ml-[2px]">Quà của tôi</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handleHistory}>
            <div className="icon-infor-li -ml-1">
              <img src={ICON_HISTORY_PARTICI} />
            </div>
            <div>Lịch sử tham gia</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li border-b-2" onClick={handlePrize}>
            <div className="icon-infor-li">
              <img src={MY_GAME} />
            </div>
            <div>Thể lệ chương trình</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
          <li className="content-info-li " onClick={handleLogout}>
            <div className="icon-infor-li">
              <img src={LOGOUT} />
            </div>
            <div>Đăng xuất</div>
            <div className="ml-auto mr-6">
              <img src={ICON_RIGHT} />
            </div>
          </li>
        </ul>
      </div>
      <PopupNotify
        isShow={isLogin}
        notify={"Thông báo"}
        content={"Bạn muốn đăng xuất khỏi hệ thống?"}
        handleOk={handleOk}
        handleCancel={handleCancel}
        labelButtonOK="Đồng ý"
        labelButtonCancel="Đóng"
      />
      <ContactPhoneZalo />
      <Logo />
    </div>
  );
}
