import IMG_TITLE from "../../assets/image/Icon/notify-yellow.png";
import HEADER from "../../assets/image/Icon/popup-tc.png";
import { Fragment } from "react";

export default function PopupPrize({
  notify,
  content,
  handleOk,
  isShow,
}: {
  notify: string;
  content: any;
  handleOk: any;
  isShow: boolean;
}) {
  return (
    <Fragment>
      {isShow && (
        <div
          className="fixed z-50 overflow-y-auto top-0 w-full left-0 show"
          id="modal"
        >
          <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-900 opacity-70" />
              <div className="content-popup-condition w-full">
                <div className="mx-4">
                  <div className=" relative -top-20">
                    <img src={HEADER} className="w-full" />
                    <div className="absolute top-10 bg-white w-full min-h-[290px] rounded-3xl px-2">
                      <div className="mt-10 font-bold-go text-[28px] text-[#0672BA]">
                        {notify}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content,
                        }}
                        className="font-regular-mon text-black text-[18px] text-left mt-3 px-3"
                      ></div>

                      <div className="mt-6">
                        <button
                          onClick={() => handleOk()}
                          className="bg-[#0672BA] font-bold-go text-[16px] text-[#ffffff] px-10 py-3 rounded-2xl mb-4"
                        >
                          Đồng ý
                        </button>
                      </div>
                    </div>
                    <div className="absolute top-0 translate-y-[25%] left-1/2 -translate-x-1/2">
                      <img src={IMG_TITLE} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
