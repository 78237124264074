import { useQuery } from "react-query";
import NOTI from "../../assets/image/Icon/notify-yellow.png";
import { useEffect, useState } from "react";
import { homeServices } from "../../services/apiService/homeService";

export default function TcLogin({
  popup,
  message,
  setCheckAgree,
  setIsOpen,
}: {
  popup: any;
  message: string;
  setCheckAgree: any;
  setIsOpen: any;
}) {
  const handleOk = () => {
    setCheckAgree(true);
    setIsOpen(false);
  };
  const { data } = useQuery<any>({
    queryKey: ["getCampsaign"],
    queryFn: () => homeServices.campaignDetailsApi("1"),
  });
  const [height, setHeight] = useState<any>();
  useEffect(() => {
    setHeight(window.screen.availHeight);
  }, []);
  return (
    <div
      className="fixed z-10 overflow-y-auto top-0 w-full left-0 show"
      id="modal"
    >
      <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-70" />
          <div className="z-50 flex justify-center">
            <div className="popup-confirm-phone absolute top-[6%] w-11/12 z-50 ">
              <div className="mt-[50px] rounded-[30px] w-full z-50">
                <img
                  src={NOTI}
                  className="image__phone w-full relative top-0 left-1/2 z-50"
                />
                <div
                  className={`containerNotify__background-tc bg-[#fff] absolute top-10 w-full z-10 ${
                    height < 750 ? "h-[375%]" : "h-[435%]"
                  }`}
                >
                  <div className="font-semibold-mon leading-4 mt-14 bg-[#fff] h-7">
                    <div className="text-[#0672BA] font-bold-go text-[24px] bg-[#ffffff] flex justify-center">
                      ĐIỀU KHOẢN & ĐIỀU KIỆN
                    </div>
                  </div>
                  <div className="containerNotify__background-listtc font-light-mon rounded-3xl z-20">
                    <div className=" z-20 mx-3">
                      <div className="font-semibold-mon text-justify leading-4 text-[13px] mt-3">
                        <ul className="mt-5 font-light-mon text-[13px] leading-[19px] corlor-text-darkblack">
                          <li>
                            <div
                              className="font-regular-mon"
                              dangerouslySetInnerHTML={{
                                __html: data?.campaign_banner_detail,
                              }}
                            ></div>
                          </li>
                          <div className="h-32"></div>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="absolute bg-white bottom-0 border-0 border-white w-full flex justify-center pb-1"
                      onClick={handleOk}
                    >
                      <div className="w-full h-28 bg-white flex items-center justify-center">
                        <button className="text-center border-btn-tc h-16 px-3 py-1 font-bold-go text-[15px] bg-[#0672BA] leading-6 text-[#ffffff] rounded-xl">
                          Tôi đồng ý các điều khoản & điều kiện <br /> của
                          chương trình
                        </button>
                      </div>
                    </div>
                    <div className="h-20"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
