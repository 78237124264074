import { Fragment, useRef, useState } from "react";
import TORIGHT from "../../assets/image/Icon/toright.png";
import COPY from "../../assets/image/Icon/icno-copy.png";
export default function GiftItem({
  infor,
  setOpenPopup,
}: {
  infor: any;
  setOpenPopup: any;
}) {
  console.log(infor);
  const [status, setStatus] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const handleToggle = () => {
    setStatus(!status);
  };
  const textAreaRef = useRef<any>(null);

  function copyToClipboard(e: any) {
    setIsShow(true);
    setTimeout(() => {
      setIsShow(false);
    }, 1500);
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }
  const handlePopupPrize = (e: any) => {
    localStorage.setItem("prize", e);
    setOpenPopup(true);
  };
  return (
    <>
      <div
        className="relative z-40 flex pt-[13px] pl-[14px]"
        onClick={handleToggle}
      >
        <div className="flex items-center">
          <img src={infor.gift_image} className="w-16 " />
        </div>
        <div className="ml-2">
          <div className="text-reponsive font-regular-mon text-[16px] leading-6">
            Bạn đã trúng phần quà là
          </div>
          <div className="font-semibold-mon text-[16px] ">{infor.gift}</div>
        </div>
      </div>
      {status === true ? (
        <div
          className={`product-infomation pl-[14px] py-2${
            infor?.exchange_status === "Chưa nhận" ? "" : ""
          }`}
        >
          {infor?.urbox_code ? (
            <div className="text-item-gift font-light-mon mt-1">
              Mã nạp card
              <div className="pr-3 font-semibold-mon text-[#363534] flex">
                <input
                  ref={textAreaRef}
                  className="font-semibold-mon text-right input-hidden-link bg-[#f0f0f0]"
                  value={infor?.urbox_code}
                />
                <div className="px-3 tooltip" onClick={copyToClipboard}>
                  <img src={COPY} />
                  {isShow ? (
                    <span className="tooltiptext font-semibold-mon">
                      Đã sao chép!!
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          {infor?.gift_type === "game_code" ? (
            <div className="text-item-gift font-light-mon mt-1">
              Mã Code
              <div className="pr-3 font-semibold-mon text-[#52A0F7] text-right">
                <input
                  ref={textAreaRef}
                  className="font-semibold-mon text-right input-hidden-link bg-[#f0f0f0]"
                  value={infor?.urbox_link}
                />
                <div className="px-3 tooltip" onClick={copyToClipboard}>
                  <img src={COPY} />
                  {isShow ? (
                    <span className="tooltiptext font-semibold-mon">
                      Đã sao chép!!
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-item-gift font-light-mon mt-1">
              Chương trình
              <div className="pr-3 font-semibold-mon text-[#3AA3A2] text-right">
                {infor.campaign_name}
              </div>
            </div>
          )}

          {/* <div className="text-item-gift font-light-mon mt-1">
            Ngày tham gia
            <div className="pr-3 font-semibold-mon corlor-text-darkblack">
              {infor.attend_date}
            </div>
          </div> */}
          {infor?.is_topup_gift ? (
            <div className="text-item-gift font-light-mon mt-1">
              Ngày đổi quà
              <div className="pr-3 font-semibold-mon corlor-text-darkblack">
                {infor.exchange_date === "" ? null : infor.exchange_date}
              </div>
            </div>
          ) : null}
          {infor?.gift_type === "urbox" && !infor?.is_top_up ? (
            <div className="text-item-gift font-light-mon mt-1">
              Ngày hết hạn sử dụng Voucher
              <div className="pr-3 font-semibold-mon corlor-text-darkblue">
                {infor?.expired_date}
              </div>
            </div>
          ) : null}
          {infor?.gift_type === "urbox" && !infor?.is_top_up ? (
            <Fragment>
              {infor?.gift_type === "card" ? null : (
                <Fragment>
                  {infor?.gift_type === "game_code" ? null : (
                    <div className="font-semibold-mon mt-1 justify-between text-[12px] text-[#333333] flex flex-nowrap">
                      Link Voucher
                      <div className="pr-3 font-italic-mon corlor-text-primaryblue text-[14px] text-right">
                        <a href={infor?.egift_link} target="_blank">
                          {infor?.egift_link}
                        </a>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          ) : null}
          {infor?.gift_type === "urbox" && !infor?.is_top_up ? (
            <div
              className="font-semibold-mon mt-1 text-[12px] text-[#333333] flex justify-between"
              onClick={copyToClipboard}
            >
              Mã bảo vệ
              <div className="flex">
                <input
                  ref={textAreaRef}
                  className="font-semibold-mon text-right input-hidden-link bg-[#f0f0f0]"
                  value={infor?.egift_pin}
                />
                <div className="px-3 tooltip" onClick={copyToClipboard}>
                  <img src={COPY} />
                  {isShow ? (
                    <span className="tooltiptext font-semibold-mon">
                      Đã sao chép!!
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <></>
      )}
      <div className="flex justify-between">
        {status === true ? (
          <div className="flex justify-center items-center">
            {infor?.is_physical_gift === true ? (
              <Fragment>
                <div className="img-code ml-2 items-center mt-2 bg-white mb-2">
                  <img
                    src={"data:image/png;base64," + infor?.qrcode}
                    // size={80}
                    width={80}
                  />
                </div>
              </Fragment>
            ) : null}
            <div
              className={`${
                infor?.is_physical_gift === true
                  ? `bg-[#0672BA] h-14 text-white rounded-xl px-3 py-1 ml-2 flex items-center`
                  : `mb-3 bg-[#0672BA] h-14 text-white rounded-xl px-3 py-1 ml-2 flex items-center`
              }`}
              onClick={() =>
                handlePopupPrize(infor?.conditions_receiving_prize)
              }
            >
              <div className="font-semibold-mon">
                Hướng dẫn
                <br /> đổi quà
              </div>
              <div className="ml-3">
                <img src={TORIGHT} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="text-[12px] font-regular-mon text-[#4F4F4F] pl-5 mt-2"
            onClick={handleToggle}
          >
            {infor?.gift_type === "urbox" && !infor?.is_top_up ? (
              <Fragment>
                Ngày hết hạn sử dụng Voucher{" "}
                <span className="text-[#3AA3A2] font-semibold-mon">
                  {infor?.expired_date}
                </span>
              </Fragment>
            ) : null}
          </div>
        )}
        <div className=" flex flex-col justify-end items-end">
          <div
            className=" bgr-icon-back flex background-bottom-icon w-10 h-10 z-30"
            onClick={handleToggle}
          >
            {status === true ? (
              <div className="icon-droptop icon-size"></div>
            ) : (
              <div className="icon-dropdown icon-size"></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
