import { useNavigate } from "react-router-dom";
import "../../assets/css/Game.css";
import { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import BG_GIFTTOP from "../../assets/image/Game/bg-success-gifttop.png";
import BG_GIFTBOTTOM from "../../assets/image/Game/bg-success-giftbottom.png";
import PopupNotify from "../../components/Popup/PopupError";
import Guide from "../../assets/image/Popup/guide.png";
import ICONGIFT from "../../assets/image/ListGift/icon-gift.png";
export default function Success({ prize }: { prize: any }) {
  console.log(prize);
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [gift, setGift] = useState();
  const [qr, setQR] = useState();
  const [isOpenGuide, setIsOpenGuide] = useState(false);

  useEffect(() => {
    let image = prize?.prize_list[0]?.image?.includes("http://");
    if (image) {
      image = prize?.prize_list[0]?.image?.replace("http://", "https://");
    } else {
      image = prize?.prize_list[0]?.image;
    }

    let qr_img = prize?.qr_code_sampling_machine?.includes("http://");
    if (qr_img) {
      qr_img = prize?.qr_code_sampling_machine?.replace("http://", "https://");
    } else {
      qr_img = prize?.qr_code_sampling_machine;
    }
    console.log(image);
    imageUrlToBase64(image)
      .then((res: any) => {
        console.log(res);
        setGift(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          // handleNavigateSucess();
        }, 1000);
      });
    imageUrlToBase64(qr_img)
      .then((res: any) => {
        console.log(res);
        setQR(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [prize]);

  const handleNavigateSucess = async () => {
    if (ref.current) {
      const canvas = await html2canvas(ref.current);
      const image = canvas.toDataURL("image/png");
      downloadImage(image, "screenshot.png");
    }
  };

  const imageUrlToBase64 = async (url: string) => {
    console.log(url);
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result as string;
          console.log(reader);
          resolve(base64data);
        };
        reader.onerror = reject;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadImage = async (image: string, filename: string) => {
    try {
      const response = await fetch(image);
      if (!response.ok) {
        throw new Error("Failed to download image");
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();

      URL.revokeObjectURL(url);
      console.log("Download successful");
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  console.log(gift);

  const handleGuie = () => {
    setIsOpenGuide(true);
  };
  const handleOk = () => {
    setIsOpenGuide(false);
  };
  return (
    <div className="bg-success" ref={ref}>
      <div className="animation-success min-h-screen">
        <nav></nav>
        <div className="relative top-0">
          <div className=" flex flex-col items-center justify-center text-center text-[24px]">
            <h1 className="mt-8 text-[25px] font-bold-mon leading-9 ani-title uppercase px-7 text-white">
              chúc mừng bạn
              <br /> đã nhận
            </h1>
          </div>
          <div className="containerSuccess__background absolute top-28 h-[100%] z-10 w-full px-7">
            <div className="containerSuccess__background-list z-20 ">
              <div className="mt-5 mb-36">
                {prize?.prize_list?.map((res: any, index: number) => (
                  <div key={index}>
                    {/* <Item res={res} qrCode={prize?.qr_code_sampling_machine} /> */}
                    <div className="mb-5 ">
                      <div className="relative top-0 text-center ">
                        <img
                          src={BG_GIFTTOP}
                          loading="lazy"
                          className="w-full"
                        />
                        <div className="absolute z-40 top-1/2 -translate-y-1/2 w-full flex flex-col items-center justify-center">
                          {gift && (
                            <img className="px-[30px] w-60" src={gift} alt="" />
                          )}

                          <div className="mt-2 text-[18px] px-8 text-[#16A5A3] font-bold-mon leading-6">
                            {parseInt(res?.quantity) > 1 && `X${res?.quantity}`}{" "}
                            {res.gift_name}
                          </div>
                          <div
                            className="text-[12px] relative z-40 font-regular-mon px-5 mt-3 ani-title"
                            dangerouslySetInnerHTML={{
                              __html: res?.conditions_receiving_prize,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="relative z-20 top-0 text-left">
                        <img
                          src={BG_GIFTBOTTOM}
                          loading="lazy"
                          className="relative w-full rounded-b-2xl"
                        />
                        <div className="absolute top-1/2 -translate-y-1/2 w-full">
                          <div className="w-[160px] relative left-1/2 -translate-x-1/2">
                            <img src={qr} className="w-[160px]" />
                          </div>
                          <div className="text-[18px] w-full font-bold-go h-full flex justify-center items-center">
                            QR nhận quà của quý khách
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="fixed z-20 flex flex-col items-center justify-around bottom-2 w-full left-0">
                  <div>
                    <button
                      className="uppercase w-36 mr-4 py-3 bg-[#16A5A3] rounded-lg text-[#ffffff] font-regular-go text-[16px]"
                      onClick={handleGuie}
                    >
                      Hướng dẫn
                    </button>
                    <button
                      className="uppercase w-36 py-3 bg-[#0672BA] rounded-lg text-[#ffffff] font-regular-go text-[16px]"
                      onClick={() => {
                        navigate("/gifts");
                      }}
                    >
                      Quà của tôi
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={handleNavigateSucess}
                      className="uppercase flex justify-center px-3 mt-2 mr-4 py-3 bg-[#DA2864] rounded-lg text-[#ffffff] font-regular-go text-[16px]"
                    >
                      Chụp màn hình
                      {/* <div> */}
                      <img src={ICONGIFT} className="ml-2" />
                      {/* </div> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupNotify
          isShow={isOpenGuide}
          notify={"Hướng dẫn sử dụng"}
          content={
            "Quý khách vui lòng đưa mã QR code vào màn hình máy để lấy quà"
          }
          handleOk={handleOk}
          labelButtonOK="Đồng ý"
          labelButtonCancel=""
          imgGuide={Guide}
        />
      </div>
    </div>
  );
}
